.header_container{
    position: fixed;
    width:100%;
    top:0;
}

.header_pt{
    top: 0;
    right: 0;
    left: 0;
    height: 86px;
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #072872;
    color: #fff;

}

.bouton_pt{
    margin-right: 20px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    /* margin-left: auto; */
}

.titre_page{
    margin-left: var(--vertical-navbar-width);
    padding-left: 80px;
}

.titre_page h1{
    margin-bottom: 0px;
}
.bouton_header{
    /* display: flex;
    justify-content: space-around; */
}

@media screen and (max-width: 1300px) {
    .titre_page{
        margin-left: var(--vertical-navbar-small-width);
        padding-left: 20px;

    }

}



@media screen and (max-width: 768px) {

    .header_pt{
        margin-top: var(--horizontal-navbar-height);

    }
    .titre_page{
        margin-left: 0px;
    }
    .titre_page h1 {
        font-size: 24px;
    }
}

