.arrayDoc {
    width : 100%;
    padding-left: 35%;
    margin-top: 100px;
    padding-top: 5px;
    margin-right: 40px;
}

@media (max-width: 1290px) {
    .arrayDoc {
        width : 100%;
        padding-left: 40%;
        margin-top: 100px;
        padding-top: 5px;
        margin-right: 40px;
    }
}


.arrayDocHeader{
    color: grey;
    border-bottom: 1px solid grey;
}
.button_docs{
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 105px;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 1000;
}
.docs_supprimer{
    position: fixed;
    right: 150px;
    top: 25px;

}
.docs_telecharger{
    position: fixed;
    right: 275px;
    top: 25px;
}

.docs_print{
    position: fixed;
    right: 400px;
    top: 25px;
}
.docs_quitter{
    position: fixed;
    right: 25px;
    top: 25px;
}

.table-active {
    align-items: center;
    vertical-align: middle;
}