.bouton_print_header{
    margin-right: 60px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: auto;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
}