.header_reglage{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3f3f3f;
    color: #fff;
    z-index: 4;

}

@media screen and (max-width: 768px) {
    .titre_reglage{
        text-align: center;
        margin: auto;
        padding-left: 0px;
    }
}

