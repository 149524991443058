.ARButton{
    margin-top : 10px;
    max-width: 100%;
    width: 300px;
    height: auto;
    background-color: #e6e9f1;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    padding: 2px 20px;
    font-weight: bold;
}

@media screen and (max-width: 461px) {
    .ARButton{
        width: 100%;
    }
}

.checkboxs-align {
    padding-left: 2.5%;
    width: 100%;
    display: flex;
    align-items: center;
}

input {
    border-radius: 0.25em;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    /*-webkit-appearance: none;*/
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

input:checked {
    background-image:
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-color: #0d6efd;
    border-color: #0d6efd;
}