

.mobile-burger-container {
  display: none;
}

.mobile-burger-button {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .mobile-burger-container {
    display: block;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 100;
    top:28px;
  }

}


@media screen and (max-width: 400px) {
  .mobile-burger-button-text {
    visibility: hidden;
  }

}