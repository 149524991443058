.content_RecoA{
    color: black;
    display: flex;
    flex-direction: column;
}

.paragraphe_Reco{
    background-color: #d8d7d7;
    padding: 30px;
}
