
.nav-link{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    width: 90%;
    height: auto;
    border: none;
    background-color: #e9ebf01a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
    transition: 0.2s;
    overflow-wrap: break-word;
}



.nav-link:hover {
    background-color: white;
    color : black;
}

.top_navbar{
    padding-top: 12px;
    margin-left: 12px;
}
.reglage_navbar{
    margin-left: 12px;
}

@media screen and (max-width: 1300px) {
    .inline-block-nav{
        display: inline-block;
    }
}


@media screen and (max-width: 768px) {

    .navbar_admin.mobile_menu_open {
        display: block;
    }

}