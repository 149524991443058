.AddValidateur_overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

}


.AddValidateur_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    width: 500px;
    max-width: 100%;
    background-color: #072872;
    color: #fff;
    padding: 1rem;
}
.AddValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.AddValidateur_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
