.content_ARnewpt{
    color: black;
    display: flex;
    flex-direction: column;
}
.checkbox_mode_operatoire{
    margin-top: 10px;
}
.checkbox_mode_operatoire form label{
    color: #8c8d8e;
}
.checkbox{
    height: 17px;
    width: 17px;
}
input[type="checkbox"].checkbox + label::before {
content:'';
border: 1px solid #00B7E8;
border-radius: 5px;
padding: 0 0.6rem;
margin-right: 20px;
}
input[type="checkbox"].checkbox:checked + label::before{
    background-color: #00B7E8;
}

.commentAR{
    height: 300px;
    background-color: #e6e9f1;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 40px;
}