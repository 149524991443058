.box_vnspt{
    border: 1px solid #072872;
    background-color: #072872;
    border-radius: 5px;
    color: #fff;
    margin-right: 35px;
    margin-top: 16px;
    padding: 32px;
    height: 190px;
    width: 230px;
}

@media screen and (max-width: 942px) {
    .box_vnspt{
        width: 100%;
    }
}

.box_vnspt_header{
    font-weight: bold;
    width: 100%;
}

.box_vnspt_subheader{
    font-weight: 500;
    width: 100%;
}
.display_vnspt{
    display: flex;
    flex-wrap: wrap;
}
.titre_vnspt{
    font-weight: bold;
    margin-top: 35px;
}
.box_vnspt_content{
    margin-top: 5px;
}
.box_vnspt_button button{
    margin-top: 15px;
    width: 167px;
    border-radius: 5px;
    border: 1px solid #7ECAC8;
    background-color: #7eaca8;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
