.KPI{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
}

.te{
    margin: 15px;
}
.te p{
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
}
.cadre_graph{
    width: auto;
    height: 128px;
    background: #F5F5F5;
    border: 1px solid grey;
    border-radius: 22px;
    box-shadow: 1px 1px 20px black;
}
.graph{
    width: 260px;
    margin: 20px 20px 0 20px;
    border: 1px solid black;
    border-radius: 22px;
}
.cadre_graph h5{
    margin-left: 30px;
    font-size: 42px;
}
.cadre{
    width: 128px;
    height: 128px;
    background: #F5F5F5;
    border: 1px solid grey;
    border-radius: 22px;
    text-align: center;
    box-shadow: 1px 1px 20px black;
}
.nombre{
    display: inline-block;
    font-size: 60px;
    margin-top: 10px;
}