.menu_contextuel button{
    border: 0;
    background-color: transparent;
}

.menu_contextuel .renameDoc{
    background-color: #7ECAC8;
    margin-left : 4%;
    border-radius: 20px;
    width : 15%;
    height: 30px;
    color: white;
}

.menu_contextuel .renameDoc:hover {
    background-color: #334e4e;
    transition-duration: 0.5s;
}

.modal_mc{
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    right : 15px;
    padding : 15px;
    cursor: pointer;
}
.modal_mc ul li{
    margin-top: 6px;
    padding-bottom: 6px;
    width: 145px;
    font-size: 14px;
    border-bottom: 1px solid black;
    cursor: pointer;
}