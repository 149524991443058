.login-container {
  background-color: #072872;
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-logo-container{
  position: fixed;
  top: 100px;
  left: 55%;
}

.login-form-container{
  width:310px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {


  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }

  .login-logo-container{
    position: initial;
    margin-bottom:100px;

  }



}

@media screen and (max-width: 400px) {
  .login-form-container{
    width: 100%;
    padding: 0px 24px;
  }
}

.m-column{
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media screen and (max-width: 400px) {
  .m-column{
    flex-direction: column;
    gap: 16px;
  }
}