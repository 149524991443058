.sommairePDF h1{
    text-align: center;
    margin-bottom: 60px;
}
.sommairePDF h2{
    text-align: center;
    margin-bottom: 40px;
}
.sommairePDF h3{
    margin-bottom: 50px;
}

.qrcode {
    text-align: center;
}
