

.navbar_pt{
    height: 100vh;
    width: var(--vertical-navbar-width);
    background-color: #072872;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

@media screen and (max-width: 1300px) {
    .navbar_pt {
        width: var(--vertical-navbar-small-width);
    }
}

@media screen and (max-width: 768px) {
    .navbar_pt {
      display: none;
      padding-top: var(--horizontal-navbar-height);
    }

    .navbar_pt.mobile_menu_open {
        display: block;
    }

    .navbar_mobile {
      position: fixed;
      height: var(--horizontal-navbar-height);
      background-color: #072872;
      border-bottom: 1px solid white;
      width:100vw;
      display: flex;
      align-items: center;
      z-index: 100;
      top:0;
    }
  }

.burger-menu-button {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}



@media screen and (min-width: 769px) {

    .navbar_mobile {
      display: none;
    }
}

@media screen and (max-width: 400px) {
  .burger-menu-button-text {
    visibility: hidden;
  }

}



.nav-link{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    /* width: 90%; */
    height: auto;
    border: 1px solid #072872;
    background-color: #e9ebf01a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.top_navbar{
    padding-top: 12px;
    margin-left: 12px;
}
.pt_navbar{
    margin-left: 12px;
}