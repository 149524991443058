.main-section{
    display: flex;
}

.tableau_pt{
    /* margin-left: 15%; */
    margin-top: calc(var(--header-height) + var(--filters-height));
    flex:1;
    margin-left: var(--vertical-navbar-width);
    /* padding-left: 12px; */
}

.tableau_header{
    position: fixed;
    display: flex;
    align-items: center;
    /* top : 185px; */
    border-bottom: 1px solid grey;
    width : calc(100% - var(--vertical-navbar-width));

    background-color: white;
    z-index: 2;
    padding: 0px 4px;


}

.tableau_content{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 70px;
    max-width : 100vw;

    background-color: #F5F5F5;
    margin-top: 10px;
    padding: 0px 4px;
    /* width : calc(100vw - var(--vertical-navbar-width)); */
}

.pt-contents{
    margin-top: var(--pt-header-height);
}



.tableau_pt .tableau_header td:not(:last-child), .tableau_pt .tableau_header th:not(:last-child){
    width: 20%;
}

.tableau_pt .tableau_header td:last-child, .tableau_pt .tableau_header th:last-child{
    width: 38px;
    transform: translateX(-18px);
    font-size: 12px;
}

@media screen and (max-width: 1300px) {
    .tableau_pt{
        margin-left: var(--vertical-navbar-small-width);
    }

    .tableau_header{
        width : calc(100% - var(--vertical-navbar-small-width));
    }
}

@media screen and (max-width: 768px) {
    .tableau_pt{
        margin-left: 0px;
        margin-top: calc(var(--horizontal-navbar-height) + var(--header-height) + var(--filters-height));
        /* padding-right: 10px; */
        max-width: 100vw;
    }

    .tableau_content td, .tableau_content th{
        font-size: 12px;
    }

    .tableau_pt .tableau_header td:last-child, .tableau_pt .tableau_header th:last-child{
        width: 34.5px;
        transform: translateX(-8px);
        font-size: 8px;

    }


    .tableau_header{
        width : 100vw;
        padding-left: 0px;
        padding-right: 0px;
        font-size: 12px;
    }
}




.suggest {
    border : 1px grey solid;
    border-radius : 5px;
    padding : 2px;
}

.suggest:hover {
    background-color: #1BA7A3;
    cursor: pointer;
}