.header_newpt{
    position: fixed;
    top: 0;
    right: 0;
    height: var(--header-newpt-height);
    /* width: 85%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #439EDB;
    color: #fff;
    z-index: 0;
    left:var(--vertical-navbar-width);
    /* z-index: 85; */
}


.titre_page_newpt{
    margin-left: 300px;
}

@media (max-width: 1290px) {
    .titre_page_newpt{
        margin-left: 260px;
    }
}

@media (max-width: 1073px) {
    .titre_page_newpt{
        margin-left: 220px;
    }
}

@media (max-width: 1000px) {
    .titre_page_newpt{
        margin-left: 210px;
    }
}

@media (max-width: 1000px) {
    .titre_page_newpt{
        margin-left: 170px;
    }
}


@media screen and (max-width: 1300px) {
    .header_newpt{
        left:var(--vertical-navbar-small-width);
    }

}

@media screen and (max-width: 768px) {
    .header_newpt{
        margin-top: var(--horizontal-navbar-height);
        left:0;
        max-width: 100vw;
    }

    .titre_page_newpt{
        margin-left: calc(var(--vertical-navbar-small-width) + 20px);
    }


}



