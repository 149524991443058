.button_imprimer_validateur{
    position: fixed;
    right: 20%;
    top: 18px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 10%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 10;
}



@media (max-width: 1290px) {
    .button_imprimer_validateur{
        position: fixed;
        right: 27%;
        top: 18px;
        border: 1px solid #7ECAC8;
        border-radius: 5px;
        background-color: #7ECAC8;
        width: 10%;
        font-size: 13px;
        height: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        z-index: 10;
    }
}

@media screen and (max-width: 768px) {
    .button_imprimer_validateur{
        top: var(--horizontal-navbar-height);
        margin-top: 10px;
        width: 100px;

    }
}

@media screen and (max-width: 500px) {
    .button_imprimer_validateur{
        top: var(--horizontal-navbar-height);
        margin-top: 40px;
        width: 100px;
        right:0;
        left: 0;
        margin-left: 10px;
        z-index: 0;
    }
}


.ImprimerValidateur_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

}
.ImprimerValidateur_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
}
.ImprimerValidateur_modal{
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 5px;
    max-width: 500px;
    width: 80%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.ImprimerValidateur_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    width: 500px;
    background-color: #072872;
    color: #fff;
    padding: 1rem;
}
.ImprimerValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.modal_button_imprimer_validateur{
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 348px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    margin-bottom: 37px;
    max-width: 100%;
}