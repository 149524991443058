@media screen{
    .PDFValidateurContent{
        display: none;
    }
}
.PDFTable { 
    /* table-layout: fixed; */
    /* border-collapse: collapse;  */
    /* border-radius: 10px; */
    /* overflow: hidden; */
    /* max-width: 760px;  */
    /* margin: 25px auto; */
    /* position: relative; */
    width: 100%;
}


.PDFTableTH:nth-child(1), .PDFTableTD:nth-child(1) {
    max-width: 10%;
}


.PDFTableTH:nth-child(2), .PDFTableTD:nth-child(2) {
    max-width: 20%;
}

.PDFTableTH:nth-child(3), .PDFTableTD:nth-child(3) {
    max-width: 20%;
}

.PDFTableTH:nth-child(4), .PDFTableTD:nth-child(4) {
    max-width: 50%;
}
.PDFTableTR{
    border: 2px solid #6ebcec;
}
.PDFTableTH, .PDFTableTD {
    padding: 35px;
    text-align: center;
}