.content_Reglage{
    color: black;
    display: flex;
    flex-direction: column;

    margin-left: var(--vertical-navbar-width);
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 120px;
}


.boutonGeneral1{
    width: 200px;
    height: 60px;
    border: 2px solid #4aabe7;
    background-color: #fff;
    color: #4aabe7;
    margin-bottom: 20px;
    text-align: center;
}
.boutonGeneral1:hover{
    border: 2px solid #4aabe7;
    background-color: #4aabe7;
    color: #fff;
}
.boutonGeneral2{
    width: 200px;
    height: 50px;
    text-align: left;
    border: 2px solid red;
    background-color: #fff;
    color: red;
    margin-bottom: 20px;
    text-align: center;
}
.boutonGeneral2:hover{
    border: 2px solid red;
    background-color: red;
    color: #fff;
}


.update-profile-settings-section{
    background-color: #072872;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: auto;
    margin-bottom: 25px;
    width: 600px;
}

.reglage-section{
    width: 100%;
}

@media screen and (max-width: 1300px) {
    .content_Reglage{
        margin-left: var(--vertical-navbar-small-width);
    }


}

@media screen and (max-width: 768px) {
    .content_Reglage{
        margin-left: 0px;
        width: 100%;
    }

    .reglage-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .boutonGeneral1, .boutonGeneral2{
        width: 100%;
    }

    .update-profile-settings-section{
        width: 100%;
    }
}