@media print {

.PDFHeaderPosition{
    display: block;
}
.imgHeader{
    position: absolute;
    top: 0px;
    right: 0;
    z-index: -1;
    margin-bottom: 50px;
}
.PDFHeader{
    z-index: 10;

    /* align-items: center; */
    overflow: hidden;
    height: 120px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    /*position: absolute;*/
}

.PDFHeaderV{
    flex-direction: column;
    justify-content:start;

}
.LeftHeader{
    padding-left: 15px;
    padding-top: 4px;
}

.redactor-field{
    height:28px;
    width:400px;
    background: white;
    margin-left: 4px;
    border: 2px solid #1BA7A3;
}

.PT-title-field{
    height:30px;
    width:500px;
    background: white;
    margin-left: 8px;
    border: 2px solid #1BA7A3;
}

.RightHeader{
    padding-right: 15px;
    padding-top: 15px;
}
}