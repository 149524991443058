:root {
    --filters-height: 70px;
    --header-height: 86px;
    --header-newpt-height: 100px;
    --horizontal-navbar-height: 60px;
    --vertical-navbar-width: 150px;
    --vertical-navbar-small-width: 150px;
    --pt-header-height: 52px;
}


@media screen and (max-width: 768px) {
    :root {
        --pt-header-height: 42px;
    }
}

@media screen and (max-width: 400px) {
    :root {
        --filters-height: 100px;
    }
}

@media screen and (max-width: 300px) {
    :root {
        --pt-header-height: 78px;
    }
}

body, p{
    margin: 0;
    font-family: 'DM Sans', sans-serif;
}

ul
{
list-style: none;
padding: 0;
margin: 0;
}



.aside-menu-content {
    margin-left: var(--vertical-navbar-width);
}

@media screen and (max-width: 1300px) {
    .aside-menu-content {
        margin-left: var(--vertical-navbar-small-width);
    }
}

@media screen and (max-width: 768px) {
    .aside-menu-content {
        margin-left: 0px;
    }
}

.aside-double-menu-content {
    margin-left: calc(2 * var(--vertical-navbar-width));
}

@media screen and (max-width: 1300px) {
    .aside-double-menu-content {
        margin-left: calc(2 * var(--vertical-navbar-small-width));
    }
}

@media screen and (max-width: 768px) {
    .aside-double-menu-content {
        margin-left: 0px;
    }
}

.under-header-content {
    margin-top: var(--header-height);
}

.content-padding {
    padding: 16px 24px;
}

.aside-menu{
    height: 100vh;
    width: var(--vertical-navbar-width);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

@media screen and (max-width: 1300px) {
    .aside-menu{
        width: var(--vertical-navbar-small-width);
    }
}

@media screen and (max-width: 768px) {
    .aside-menu{
      display: none;
      padding-top: var(--horizontal-navbar-height);
    }

    .aside-menu.mobile_menu_open, .aside-menu.mobile-menu-open {
        display: block;
    }
}

.desktop-only{
    display: block;
}

@media screen and (max-width: 768px) {
    .desktop-only{
        display: none;
    }
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 768px) {
    .mobile-only {
        display: block;
    }
}

.table > :not(caption) > * > * {
    padding: 2px;
}



button.reset-button, input[type="submit"].reset-button, input[type="reset"].reset-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}