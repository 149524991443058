.navbar_newpt{
    padding-left: 12px;
    height: 100vh;
    width: var(--vertical-navbar-width);
    background-color: #439EDB;
    color: #fff;
    position: fixed;
    margin-left: var(--vertical-navbar-width);
    z-index: 90;
    top: 0;
}
.numero_newpt{
    height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    max-width: var(--vertical-navbar-width);
    word-break: break-all;
}
.numero_newpt p{
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 65px;
}
.content_navbar_newpt{
    margin-top: 65px;
}
.navlink_newpt{
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    width: 95%;
    height: auto;
    border: 1px solid #439EDB;
    background-color: #e9ebf01a;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.burger-newpt{
    display: none;
    /* z-index: 91; */

}

@media (max-width: 1300px) {
    .navbar_newpt{
        padding-left: 12px;
        height: 100vh;
        width: var(--vertical-navbar-small-width);
        background-color: #439EDB;
        color: #fff;
        position: fixed;
        margin-left: var(--vertical-navbar-small-width);;
        z-index: 1;
    }

    .navlink_newpt{
        text-decoration: none;
        color: #fff;
        font-size: 13px;
        width: 95%;
        height: auto;
        border: 1px solid #439EDB;
        background-color: #e9ebf01a;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .content_navbar_newpt{
        margin-top: 65px;
    }

    .numero_newpt{
        height: 40px;
        max-width: var(--vertical-navbar-small-width);
    }
}

@media (max-width: 1000px) {

    .navlink_newpt{
        text-decoration: none;
        color: #fff;
        font-size: 13px;
        width: 95%;
        height: auto;
        border: 1px solid #439EDB;
        background-color: #e9ebf01a;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 768px) {
    .navbar_newpt{
        height: calc(100vh - var(--horizontal-navbar-height));
        width: var(--vertical-navbar-small-width);
        left:0;
        margin-left: 0px;
        top: var(--horizontal-navbar-height);

    }

    .content_navbar_newpt{
        margin-top: 65px;
    }

    .numero_newpt{
        height: 26px;
        margin-top: 40px;
        max-width: var(--vertical-navbar-small-width);

    }



    .navbar_newpt.navbar_newpt_open {
        width:0;
    }

    .navbar_newpt.navbar_newpt_open .hiddeable{
        display: none;
    }

    .burger-newpt{
        display: block;
        position: absolute;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}




