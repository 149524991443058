
.bandeau_filtre{
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0; */
    /* margin: 86px auto; */
    height: var(--filters-height);
    background-color: #7ECAC8;
    display: grid;
    grid-template-columns: repeat(5, 1fr) 50px;
    /* grid-gap: 8px; */
    column-gap: 4px;
    padding: 4px;
    align-items: center;
    /* grid-auto-rows: minmax(50px, auto); */
}

.bandeau_filtre input {
    max-width: 200px;
    width: 100%;
    height: 20px;
    padding-left: 5px;
    border-radius: 5px;
    border: none;
}

@media screen and (min-width: 400px) {
    #filtreNumPT{
        grid-column: 2;
        grid-row: 1;
    }

    #title-filter{
        grid-column: 3;
        grid-row: 1;
    }

    #ee-filter{
        grid-column: 3;
        grid-row: 2;
    }

    #begin-date{
        grid-column: 4;
        grid-row: 1;
    }

    #end-date{
        grid-column: 4;
        grid-row: 2;
    }

    #redactor{
        grid-column: 5;
        grid-row: 1;
    }
}

@media screen and (max-width: 768px) {

    .bandeau_filtre { 
        grid-template-columns: repeat(5, 1fr) 30px;
    }
    .bandeau_filtre input {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px) {

    .bandeau_filtre{
        grid-template-columns: repeat(2, 1fr);
    }
}

.filter {
    width: 50px;
}