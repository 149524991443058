.button_add_validateur{
    position: fixed;
    right: 2%;
    top: 10px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 15%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 1000;
}

@media (max-width: 1290px) {
    .button_add_validateur{
        position: fixed;
        right: 31px;
        top: 10px;
        border: 1px solid #7ECAC8;
        border-radius: 5px;
        background-color: #7ECAC8;
        font-size: 13px;
        width: 20%;
        height: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        z-index: 1000;
    }
}


@media screen and (max-width: 768px) {
    .button_add_validateur{
        top: var(--horizontal-navbar-height);
        margin-top: 10px;
    }
}


.button_add_qrcode{
    position: fixed;
    right: 2%;
    top: 50px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 15%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 1000;
}

@media (max-width: 1290px) {
    .button_add_qrcode{
        font-size: 13px;
        right: 31px;
        width: 20%;
    }
}


@media screen and (max-width: 768px) {
    .button_add_qrcode{
        top: var(--horizontal-navbar-height);
        margin-top: 60px;
    }
}

@media screen and (max-width: 500px) {
    .button_add_qrcode{
        top: var(--horizontal-navbar-height);
        margin-top: 65px;
        left:180px;
        width: 140px;
    }
}

.AddValidateur_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

}
.AddValidateur_wrapper {
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
}
.AddValidateur_modal{
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 5px;
    max-width: 500px;
    width: 80%;
    height: 760px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.AddValidateur_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    max-width: 100%;
    width: 500px;
    background-color: #072872;
    color: #fff;
    padding: 1rem;
}
.AddValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.AddValidateur_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AddValidateur_content_input{
    width: 350px;
    max-width: 100%;
    height: 50px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    padding-left: 10px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .AddValidateur_content_input{
        width: 100%;
    }

    .AddValidateur_modal{
        width: 100%;
    }
}