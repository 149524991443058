.bandeau{
    height: 80px;
    background-color: #072872;
    color: white;
}
.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 80px;
}
.avatar{
    display: flex;
    text-align: center;
    align-items: center;
}
.avatar img{
    height: 45px;
}
.avatar h3{
    font-size: 20px;
    margin-left: 20px;
}
.blanc{
    color: #fff;
}