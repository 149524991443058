.box_vspt{
    border: 1px solid #eaecf0;
    background-color: #eaecf0;
    border-radius: 5px;
    margin-right: 35px;
    margin-top: 16px;
    padding: 32px;
    width: 230px;
}

@media screen and (max-width: 942px) {
    .box_vspt{
        width: 100%;
    }
}

.display_vspt{
    display: flex;
    flex-wrap: wrap;
}
.box_vspt_button{
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.box_vnspt_header{
    margin-bottom: 10px;
}
.check {
    margin-right: 10px;
}