.content_KPIR{
    color: black;
    display: flex;
    flex-direction: column;
    margin-left: var(--vertical-navbar-width);
    margin-top: var(--header-height);
}


@media screen and (max-width: 1300px) {
    .content_KPIR{
        margin-left: var(--vertical-navbar-small-width);
    }


}

@media screen and (max-width: 768px) {
    .content_KPIR{
        margin-left: 0px;
    }

    .save-button-KPIR{
        margin: auto;
    }
}