.overlayfailedlogin {
    display:none;
    position: fixed;
    inset:0;
    background: rgba(0,0,0,0.5);
    z-index: 9;
}

.modal{
    display:none;
    flex-direction:column;
    flex-wrap: wrap;
    position:fixed;
    width: 50%;
    height: 50%;
    top:25%;
    left:25%;
    bottom: 25%;
    background: #072872;
    border-radius: 0.25rem;
    z-index:10;
    text-align:center;
    color: white;
    border-radius: 25px;
}

.textfailedlogin{
    width: 100%;
    height: 70%;
    padding: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}
.bouttonwrapper{
    width: 100%;
    height: 30%; 
    align-items:end;
    display: flex;
    justify-content: space-evenly;
    background-color: white;

}

.ResetRGPD{
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    margin-bottom: 37px;
    max-width: 100%;

}
