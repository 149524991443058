@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }

    .sectionSpacing {
        margin-bottom: 50px;
    }
}
.PDFContentPT{
    font-size: 0.8em
}
.titrePDF{
    text-align: center;
    text-decoration: underline 5px #4aabe7;
}
.theadValidation{
    background-color: #4aabe7;
    color: #fff;
}
.table{
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
}

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
	border: 2px solid #4aabe7;
}
.pageFooter {
    display: table-footer-group;
}

.pageFooter:after {
    counter-increment: page;
    content: counter(page);
}