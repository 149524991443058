.content_descnewpt{
  margin-top: var(--header-newpt-height);
  color: black;
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--vertical-navbar-width) * 2);
  /* width:  calc(100vw - var(--vertical-navbar-width) * 2); */
  padding-left: 12px;
  padding-right: 12px;
}
.content_descnewpt input:not([type="checkbox"]) {
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  /* margin-right: 50%; */
  margin-top: 10px;
  padding-left: 10px;
}
.name_newpt{
  font-weight: bold;
  margin-top: 26px;
}
.info_rdp_newpt{
  margin-top: 26px;
}
.info_rdp_newpt p{
  color: #8c8d8e;
  padding-bottom: 20px;
}
.content_descnewpt_input{
  display: flex;
  flex-direction: column;
}
.content_descnewpt_input textarea {
  height: 240px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  width: 100%;
  margin-bottom: 50px;
  border-radius: 5px;
}
.button_add_do button{
  width: 405px;
  max-width: 90vw;
  height: 50px;
  background-color: #4aabe7;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 26px;
  border-radius: 5px;
  border: none;
}
.button_add_do button:hover{
  background-color: #fff;
  color: #4aabe7;
  border: 2px solid #4aabe7;
}

.button_add_do {
  max-width: 90vw;

}
.border_grey_newpt{
  border-bottom: 1px solid #8c8d8e;
}

@media (max-width: 1300px) {
  .content_descnewpt{
       margin-left: calc(var(--vertical-navbar-small-width) * 2);
    /* width:  calc(100vw - var(--vertical-navbar-small-width) * 2); */

  }
}

@media screen and (max-width: 768px) {
  .content_descnewpt{
      margin-top: calc(var(--header-newpt-height) + var(--horizontal-navbar-height));
      margin-left: 10px;
      width:  96vw;

  }


}

.custom-checkbox{
  min-width: 16px;
}