.signature-canvas {
  border: 2px dotted #323232 ;
  width: 400px;
  height: 200px;
}

.signature-canvas-container {
  position: relative;
  width: 400px;
  height: 200px;
}

.signature-canvas-message{
  position: absolute;
  top:50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  color:#bdbdbd;
  pointer-events: none;
}

.signature-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.signature-wrapper {
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.signature-inside{
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 500px;
  width: 500px;
  height: 475px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.signature-image-preview{
  width: 400px;
  height: 200px;
  object-fit: contain;
}

@media screen and (max-width: 500px) {
  .signature-inside{
    width: 100%;
    min-height: 100vh;
    border-radius: 0;
  }

  .signature-canvas {
    border: 2px dotted #323232 ;
    width: 90vw;
    height: 200px;
  }

  .signature-canvas-container {
    position: relative;
    width: 90vw;
    height: 200px;
  }

  .signature-image-preview{
    width: 90vw;
    height: 200px;
    object-fit: "contain";
  }
}

.signature-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  height: 78px;
  max-width: 100%;
  width: 500px;
  background-color: #072872;
  color: #fff;
  padding: 1rem;
}

.signature-header button {
  border-radius: 20px;
  width: 44px;
  height: 44px;
  background-color: #7ECAC8;
  border: 1px solid #7ECAC8;
  color: #fff;
}

.signature-content{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signature-options-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  margin: 8px 0 0;
  gap: 12px;
}


.signature-options-container button {
  border: 1px solid #555a5a;
  border-radius: 4px;
  background-color: #c3cfcf;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #161616;
}

.signature-buttons-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  margin: 1.5rem 0 0;
  gap: 24px;
}

.signature-button {
  border: 1px solid #7ECAC8;
  border-radius: 4px;
  background-color: #7ECAC8;
  padding: 8px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.signature-button:active {
  background-color: #589896;
  border: 1px solid #7ECAC8;
  color: #fff;
}

.signature-button:disabled {
  background-color: #c3cfcf;
  border: 1px solid #c3cfcf;
  color: #555555;
  cursor: not-allowed;
}