.content_Utilisateurs {
    color: white;
    display: flex;
    flex-direction: column;
}

.user-settings-show-profiles {
    margin-top: var(--header-height);
    position: fixed;
    top: initial;
    padding-top: 30px;
}

@media screen and (max-width: 768px) {
    .user-settings-show-profiles {
        top: initial;
        padding-top: 30px;
    }
}

.user-settings-table-header {
    padding-top: calc(var(--header-height));
}
