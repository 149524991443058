.button_add_validateur{
    position: fixed;
    right: 2%;
    top: 10px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 15%;
    height: auto;
    top: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 1050;
}

@media (max-width: 1290px) {
    .button_add_validateur{
        position: fixed;
        right: 31px;
        top: 10px;
        border: 1px solid #7ECAC8;
        border-radius: 5px;
        background-color: #7ECAC8;
        font-size: 13px;
        width: 20%;
        height: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        z-index: 1000;
    }
}


@media screen and (max-width: 768px) {
    .button_add_validateur{
        top: var(--horizontal-navbar-height);
        margin-top: 10px;
    }

    .button_add_validateur_user{
        position:relative;
        width: 140px;
        left:0;
        top:0;
        z-index: 3;
    }
}

@media screen and (max-width: 500px) {
    .button_add_validateur{
        top: var(--horizontal-navbar-height);
        margin-top: 65px;
        width: 160px;
        right:0;
        left: 0;
        margin-left: 10px;
        z-index: 3;
    }
}

.AddValidateur_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.5);

}


.AddValidateur_headerR{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    width: 500px;
    max-width: 100%;

    background-color: black;
    color: #fff;
    padding: 1rem;
}
.AddValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.AddValidateur_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}
